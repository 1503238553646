* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kdam Thmor Pro", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}

.nav-item-underline :hover {
  text-decoration: underline !important;
}

#text-red {
  color: #e51d23;
  font-size: 20px;
}

/* #text-red :hover {
  text-decoration: underline;
} */

.carousel-item img {
  width: 100%;
  height: 30vh;
}

.img_2_text {
  color: #faf9f6;
}

.collapsing {
  text-align: center;
}

.show {
  text-align: center;
}

.text_carousel {
  display: block;
}

.centered_text {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calendar {
  margin-top: 15px;
  margin-bottom: 50px;
}

/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Kdam Thmor Pro", sans-serif;
    font-family: "Quicksand", sans-serif;
    font-family: "Open Sans", sans-serif;
    font-family: "Roboto", sans-serif;
  }

  .carousel-item img {
    width: 100%;
    height: 88.5vh;
  }

  .calendar {
    margin-top: 85px;
    margin-bottom: 50px;
  }

  .carousel-caption {
    bottom: 25%;
  }

  .img_1 {
    left: -30%;
  }

  .img_2 {
    right: -50%;
  }

	.nav-item-underline :hover {
  text-decoration: underline;
}
	
  .text_carousel {
    display: block;
  }

  .centered_text {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
